
import { GetUserMixin, Storage } from '@rtl/ui'
import { mixins, Options, Watch } from 'vue-property-decorator'

import Modal from '@/views/components/Modal.vue'
import { MainLayout } from '@/views/layouts'

@Options({
  name: 'App',
  components: {
    MainLayout,
    Modal
  },
  head () {
    return {
      title: 'Fiók - RTL Magyarország',
      description:
        'Kezeld az RTL Magyarország fiókodat egy helyen.'
    }
  }
})
export default class App extends mixins(GetUserMixin) {
  showPrivacyModal = false

  closePrivacyModal () {
    this.showPrivacyModal = false
  }

  @Watch('user', { immediate: true })
  chechPrivacyModal (newVal: unknown, oldVal?: unknown) {
    if (newVal && !oldVal && new Date().getFullYear() === 2024) {
      const expire = Storage.get('privacy-2024-modal-close')
      if (!expire || Date.now() > expire + 365 * 24 * 60 * 60 * 1000) {
        this.showPrivacyModal = true
        Storage.set('privacy-2024-modal-close', Date.now())
      }
    }
  }
}
