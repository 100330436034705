import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "modal__panel" }
const _hoisted_2 = {
  key: 0,
  class: "modal__header"
}
const _hoisted_3 = { class: "modal__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Close = _resolveComponent("Close")!

  return (_openBlock(), _createElementBlock("div", {
    class: "modal modal--user",
    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.closeable ? _ctx.$emit('close') : null), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.closeable)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_Close, {
              class: "modal__close",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ]))
}